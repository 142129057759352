import * as React from 'react'
import NewYear from "../../components/VideoPage/VideoPage";

const NewYear2017 = () => {
  return (
      <NewYear
        url="https://tfproductionmediaservice-euwe.streaming.media.azure.net/2f69fda7-7897-43f1-9bef-32c71c8959bf/Emso-NYE-2017-render-x2-10.ism/manifest"
      />
  )
}

export default NewYear2017;

